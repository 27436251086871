.card {
    min-width: 100%;
    // min-height: 95%;
    height: 95%;
    max-height: 100%;
    max-width: 100%;
    margin: 0.5rem 0rem;
    border: 0px;
    padding: 0px 0px;
    display: flex;
    justify-content: center;
    // align-items: center;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 2px 2px 8px #b5b5b5, -2px -2px 8px #ffffff;
    transition: all 250ms;
  }
  
  .dashboard__home {
    margin: 0.25rem 1rem;
  }
  #chart-0 {
    /* z-index: -999; */
    margin-top: -20px;
  }
  .grid-row {
    min-width: 100%;
  }
  // .card-body {
  // z-index: 90;
  // }
  .card h3 {
    color: #2d3744;
    font-size: 1.2em;
  }
  .consumption {
    color: blue;
    height: 120px;
  }
  .cost {
    color: red;
    height: 120px;
  }
  .dash-map-container {
    margin-left: 7%;
    width: 92%;
  
    margin-top: 25px;
    border-radius: 50px;
  }
  
  .chart-a {
    max-height: 40%;
    max-width: 80%;
    display: flex;
    flex: 2;
    flex-direction: row;
  }
  #map {
    margin-left: 100px;
    width: calc(100vw-100px);
    height: 100vh;
  }
  #dash-map {
    width: 100%;
    height: 50vh;
  }
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  .map__marker__image {
    cursor: pointer;
    pointer-events: all;
    // width: 52px;
    // height: 52px;
  }
  .cluster__marker {
    position: relative;
    .cluster__card {
      z-index: 5;
      box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.285),
        0 1px 5px 0 rgba(0, 0, 0, 0.05);
      position: absolute;
      min-width: 200px;
      border-radius: 7.5px;
      padding: 1rem 1.5rem;
      background: rgba(255, 255, 255, 1);
      transform: translate(0%, -100%);
      animation: 250ms fade-in ease-in-out;
  
      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .map__marker {
        cursor: pointer;
        pointer-events: all;
  
        marker__circle {
          width: 32px;
          height: 32px;
          background: blue;
        }
      }
  
      .location__address {
        font-size: 0.75rem;
        font-weight: 500;
      }
      #cluster__close__icon {
        background: white;
        z-index: 5;
        pointer-events: all !important;
        border-radius: 50px;
        color: red;
        border: none;
        cursor: pointer;
        position: absolute;
        bottom: -1rem;
        left: -0.5rem;
        font-size: 1rem;
        padding: 0.5rem;
        box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.285),
          0 1px 5px 0 rgba(0, 0, 0, 0.05);
  
        > span > svg {
          width: 0.75em;
          height: 0.75em;
        }
      }
      h2 {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
  }
  .map__marker__image {
    z-index: 4;
  }
  .cluster-marker-circle {
    color: white;
    background: #1978c8;
    border-radius: 50px;
    // width: 1.75rem;
    // height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .demonstration__content{
    h2{
      color:#012345;
      font-size: 2rem;
    }
    p{
      font-size: .9rem;
      color: #b48484;
    }
  }