@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
:root {
  font-size: 16px;
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --accent-color:#012345;
  --text-primary: #000000;
  --text-secondary: #202124;
  --text-tertiary: #4c4c4c;
  --btn-primary: #0005df;
  --btn-secondary: #ffffff;
  --hover-state: #e9eaff;
  --focused-state: #123456;
  --transition-speed: 250ms;
  --sidebar-width: 5rem;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

main {
  margin-left: 5rem;
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
}